import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import {Link, useLocation} from 'react-router-dom';
import { useHistory } from "react-router-dom";
import poll from 'easy-polling';
import CircularProgress from '@material-ui/core/CircularProgress';
import url from "../../util/routeURL";
import removeCSS from "../../util/RemoveCSS"
import { GrView } from 'react-icons/gr';
import { BiHide } from 'react-icons/bi';
import './style.css';
import ApiService from "../../ApiService";
import useUserStore from "../../hook/useUserStore";
import {isValidValue} from "../../util/common";

const LogInForm = (props) => {
    const [remMeChecked, setRemMeChecked] = useState(false)
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(props.email?.trim()?.toLowerCase());
    const [password, setPassword] = useState();
    const [errors, setErrors] = useState(props.error);
    const [placeHolder, setPlaceHolder] = useState(null);
    const [signinStatus, setSigninStatus] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const history = useHistory();
    const patient = useUserStore((state) => state.patient);
    const setPatient = useUserStore((state) => state.setPatient);
    const isAuth = useUserStore((state) => state.isAuth);
    const setIsAuth = useUserStore((state) => state.setIsAuth);
    const location = useLocation();

    // useEffect(() => {
    //     const loginUrl = `${process.env.REACT_APP_MAIN_SITE_BASE_URL}/sign-in?redirect=patient-portal`;
    //     window.open(loginUrl, '_self');
    // }, [])

    removeCSS();
    document.body.classList.add("body_login");

    const showConfirmationForm = () => {
        let urlAddress = "/confirm";
        if (email) {
            urlAddress = `/confirm?email=${email}`;
        }
        history.push(urlAddress);
    }

    const signInStatusChecker = (res) => {
        return res === true;
    }

    const getSignInStatus = async () => {
        return signinStatus;
    };

    const doSignInAsync = async (event) => {
        setSigninStatus(false);
        let isSuccess = false;
        return await Auth.signIn({
            username: email,
            password,
        })
            .then(async () => {
                await Auth.currentSession().then(sess => {
                    localStorage.setItem("loggedInToken", sess.getIdToken().getJwtToken());
                    localStorage.setItem("countdownTime", new Date());
                    setIsAuth(true);
                });

                await ApiService.getPatientAfterSessRef().then(res => {
                    setPatient(res?.data);
                    if(res?.data) sessionStorage.setItem('h_prof', 'true');
                });
                isSuccess = true;
                const urlSearchParams = new URLSearchParams(location?.search);
                const next = urlSearchParams.get('next');
                const goTo = isValidValue(next) ? `/${next}` : url.home;
                history.push(goTo);
            })
            .catch(err => {
                event.stopPropagation();
                setErrors("You must confirm your account");
                if (err.code === "UserNotConfirmedException") {
                    return (showConfirmationForm());
                } else if (err.code === "UserNotFoundException") {
                    setErrors("User does not exist");
                } else {
                    setErrors(err.message);
                }
                isSuccess = false;
            }).finally(() => {
                setLoading(false);
                return {status: true};
            });
    };

    const handleSignInV2 = async (event) => {
        await handleSignIn(event);
        poll(getSignInStatus, signInStatusChecker, 500, 8000)
            .then(res => res)
            .catch(err => err);
        const urlSearchParams = new URLSearchParams(location?.search);
        const next = urlSearchParams.get('next');
        const goTo = isValidValue(next)
            ? next?.startsWith('/') ? next : `/${next}`
            : url.home;
        history.push(goTo);
    }

    const handleSignIn = async (event) => {
        setSigninStatus(false);
        event.preventDefault();
        rememberMe();
        setErrors("");
        setLoading(true);
        await doSignInAsync(event);
        setSigninStatus(true);
    }

    const rememberMe = () => {
        if (remMeChecked && email !== "") {
            localStorage.username = email;
            localStorage.checkbox = remMeChecked;
            setPlaceHolder(localStorage.username);
        } else {
            localStorage.username = "";
            localStorage.checkbox = "";
        }
    };

    useEffect(() => {
        if (localStorage.username) {
            setEmail(localStorage.username?.trim()?.toLowerCase())
        } else {
            setPlaceHolder("Email Address");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.username]);

    return (
        <div className="form red-swing-bg" id="login-form">
            <div className="form-body z-1">
                <div className="panel-form" id="panel">
                    <form onSubmit={handleSignInV2} id="signinForm" className="needs-validation login_form signup_form" noValidate>
                        <h1 className="myAccount">My Account</h1>
                        <span id="errorSpan" >{errors}</span>
                        <div className="row mt-30">
                            <div className="login_form_element">
                                <label for="signin-email" className="signup-label">
                                    Email Address
                                </label>
                                <input
                                    type="text"
                                    id="signin-email"
                                    className={`login_inputs ${errors && !email ? 'red-outline' : ''}`}
                                    placeholder={placeHolder}
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value?.trim()?.toLowerCase())}
                                />
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="login_form_element login-password">
                                <label for="signin-password" className="signup-label">
                                    Password
                                </label>
                                <input
                                    type={viewPassword ? "input":"password"}
                                    id="signin-password"
                                    className={`login_inputs ${errors && !password ? 'red-outline' : ''}`}
                                    placeholder="Password"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span className="viewPassword" > { viewPassword ?<BiHide onClick={()=>{setViewPassword(false)}} /> :<GrView  onClick={()=>{setViewPassword(true)}}/> }</span>
                            </div>
                        </div>
                        <div className="util_auth">
                            <div className="checkbox_container">
                                <input type="checkbox" id="remember_me" className="checkbox_login" checked={remMeChecked} onChange={() => setRemMeChecked(!remMeChecked)} />
                                <label for="remember_me" className="checkbox_label" id="remember_label">Remember me</label>
                            </div>
                            <Link className="link-prop" to={url.forgotPword}>Forgot password?</Link>
                        </div>
                        <div className="flex-right">
                            {!loading ?
                                <button type="submit" className="btn socialbutton-customizable" id="socialbutton-customizable">
                                    Log In
                                </button>
                                :
                                <button type="button" className="btn socialbutton-customizable" id="socialbutton-customizable">
                                    Log In
                                    <CircularProgress className="loading-button-spinner" />
                                </button>
                            }
                            <span className="fg-1"></span>
                            <p className="signup-link-label">
                                <span>Don't have an account? &nbsp;</span>
                                <span className="link-prop signupClick" onClick={() => window.location.href = `${process.env.REACT_APP_MAIN_SITE_BASE_URL}/sign-up`}>Sign Up</span>
                            </p>
                        </div>

                    </form>

                </div>
            </div>
            <div className="curved-login">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 219"><path fill="rgba(238, 129, 85, 0.3)" fill-opacity="1" d="M0,64L120,96C240,128,480,192,720,181.3C960,171,1200,85,1320,42.7L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
            </div>
        </div>
    )
};

export default LogInForm;