import React, { useState, useEffect, useRef, useMemo } from 'react'
import { AiOutlineCalendar, AiOutlineClose } from 'react-icons/ai'
import { TextField, Select, InputLabel, MenuItem } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import { Auth } from 'aws-amplify'
import ApiService from '../../ApiService'
import MuiAlert from '@material-ui/lab/Alert'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import './style.css'
import './check-box.css'

const ManageSubscription = props => {
  const [basicModal, setBasicModal] = useState(false)
  const toggleShow = () => setBasicModal(!basicModal)

  const [stripePortalUrl, setStripePortalUrl] = useState(null)
  const hasFetchedSubs = useRef(false)

  const [subscriptionList, setSubscriptionList] = useState([])
  const [selectedSub, setSelectedSub] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [nextShipmentDate, setNextShipmentDate] = useState('')
  const [updateShipmentDate, setUpdateShipmentDate] = useState('')
  const [email, setEmail] = useState(null)
  const [changeRequested, setChangeRequested] = useState(null)
  const [showSkipModal, setShowSkipModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [isActionLoading, setIsActionLoading] = useState(false)

  const [snackOpen, setSnackOpen] = useState(false)
  const [snackSeverity, setSnackSeverity] = useState('success') // 'error' or 'success'
  const [snackMessage, setSnackMessage] = useState('')

  const showSnack = (message, severity = 'success') => {
    setSnackMessage(message)
    setSnackSeverity(severity)
    setSnackOpen(true)
  }

  const handleSnackClose = (_, reason) => {
    if (reason === 'clickaway') return
    setSnackOpen(false)
  }

  const fetchUserData = async () => {
    try {
      setIsLoading(true)
      const user = await Auth.currentAuthenticatedUser()
      const profile = await ApiService.getPatientAfterSessRef()
      setEmail(profile.data.email)
      const { data } = await ApiService.getSubcriptionSession(profile.data.email)
      if (data.url) setStripePortalUrl(data.url)
      const subscriptionsData = await ApiService.getSubscriptionsList(profile.data.email)
      setSubscriptionList(subscriptionsData.data.subscriptions)
      const activeSubs = subscriptionsData.data.subscriptions.filter(sub => sub.status === 'active' || sub.status === 'trialing')
      setSelectedSub(activeSubs[0])
    } catch (error) {
      console.error('Error fetching user email', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  const getDateFromEpoch = (epoch, type) => {
    if (!epoch) return ''
    const date = new Date(epoch * 1000)
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    if (type === 'input') {
      return date.toISOString().split('T')[0]
    } else {
      return formatter.format(date)
    }
  }

  const changeBillingDate = async () => {
    try {
      if (!updateShipmentDate) {
        showSnack('Please select a new shipment date.', 'error')
        setShowSkipModal(false)
        setChangeRequested(false)
        return
      }
      if (updateShipmentDate < new Date()) {
        showSnack('Please select a future date.', 'error')
        setShowSkipModal(false)
        setChangeRequested(false)
        return
      }
      if (selectedSub?.metadata?.trial_used === 'true') {
        showSnack('You have already used your one-time shipment date change for this subscription.', 'error')
        setShowSkipModal(false)
        setChangeRequested(false)
        return
      }

      setIsActionLoading(true)

      const newBillingCycleAnchor = Math.floor(updateShipmentDate.getTime() / 1000)

      const response = await ApiService.changeBillingDate(selectedSub.id, newBillingCycleAnchor, selectedSub.price.id)

      console.log('response', response)
      showSnack('Shipment date updated successfully!', 'success')
      fetchUserData() // Refresh subscription info
    } catch (error) {
      const message = error?.response?.data?.error || error?.message || 'Failed to update shipment date. Please try again.'
      showSnack(message, 'error')
      console.error('Error changing billing date', message)
    } finally {
      setIsActionLoading(false)
      setShowSkipModal(false)
      setChangeRequested(false)
      setShowUpdateModal(false)
    }
  }

  const getNextBillingDateAfterSkip = () => {
    const currentEnd = selectedSub?.current_period_end // in seconds
    const interval = selectedSub?.price?.interval // e.g. 'month'
    const count = selectedSub?.price?.interval_count || 1

    let nextDate = new Date(currentEnd * 1000)

    if (interval === 'month') {
      nextDate.setMonth(nextDate.getMonth() + count)
    } else if (interval === 'year') {
      nextDate.setFullYear(nextDate.getFullYear() + count)
    } else {
      nextDate = new Date(nextDate.getTime() + count * 86400 * 1000) // fallback days
    }

    return nextDate
  }

  const confirmSkipShipment = async () => {
    setIsActionLoading(true)
    const skipDate = getNextBillingDateAfterSkip()

    try {
      const response = await ApiService.skipShipment(selectedSub.id, skipDate.toISOString())
      console.log('Skip shipment response:', response)
      alert('Shipment skipped successfully!') // ✅ You can replace this with a nicer toast
      setShowSkipModal(false)
      fetchUserData() // optionally refresh subscription data
    } catch (error) {
      const message = error?.response?.data?.error || error?.message || 'Something went wrong while skipping your shipment.'

      // 🎯 Show error clearly
      if (message.includes('Skip already used')) {
        setShowSkipModal(false)
        showSnack('You have already used your one-time skip for this subscription.', 'error')
      } else {
        alert(message)
      }

      console.error('Error skipping shipment:', message)
    } finally {
      setIsActionLoading(false)
      setShowUpdateModal(false)
    }
  }

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading"></div>
      </div>
    )
  }

  if (subscriptionList?.length === 0) {
    return (
      <div className="basic-center">
        <div className="shipment-controls">
          <h3 className="portal-title">You have no active subscriptions</h3>
          <br />
          <p className="portal-desc">
            Head over to{' '}
            <a target="_blank" href="https://www.tbd.health/">
              TBD Health
            </a>{' '}
            to purchase a subscription kit
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="manage-subscription">
      <Snackbar open={snackOpen} autoHideDuration={5000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <MuiAlert elevation={6} variant="filled" onClose={handleSnackClose} severity={snackSeverity}>
          {snackMessage}
        </MuiAlert>
      </Snackbar>

      {isActionLoading && (
        <div className="loading-container">
          <div className="loading"></div>
          <div>Please wait</div>
        </div>
      )}

      {stripePortalUrl && (
        <div className="stripe-portal-wrapper">
          <div className="stripe-portal-card" onClick={() => (window.location.href = stripePortalUrl)}>
            <div className="stripe-portal-left">
              <AiOutlineCalendar size={36} className="portal-icon" />
            </div>
            <div className="stripe-portal-right">
              <h3 className="portal-title">Manage Subscription</h3>
              <p className="portal-desc">
                View upcoming invoices, change your payment method, update billing details, or modify your subscription directly in the secure portal.
              </p>
              <button className="portal-button">Subscription Details</button>
            </div>
          </div>
        </div>
      )}

      <p className="portal-desc">Want to Skip a Shipment or Change your Shipment Date?</p>

      <div className="shipment-controls">
        <h3 className="portal-title">Manage Shipment</h3>
        <p className="portal-desc">
          Please note that you can only skip a shipment upto 14 days before the next shipment date. You can skip or change the billing date only once per subscription.
        </p>
        {changeRequested && (
          <>
            <h3 className="text-input-label">Select a Subscription</h3>
            <br />
            <select
              className="styled-calendar"
              value={selectedSub?.id}
              onChange={e => {
                setSelectedSub(subscriptionList.find(sub => sub.id === e.target.value))
                setChangeRequested(false)
              }}
            >
              <option value="" disabled>
                Select your subscription
              </option>
              {subscriptionList
                ?.filter(sub => sub.status === 'active' || sub.status === 'trialing')
                .map(sub => (
                  <option key={sub.id} value={sub.id}>
                    {sub.productName} - {sub.duration} (${sub.price.unit_amount / 100})
                  </option>
                ))}
            </select>
            <br />
          </>
        )}

        {selectedSub && !changeRequested && (
          <p className="portal-desc" style={{ width: '100%', color: 'black', display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Selected Subscription:{' '}
            <b>
              {selectedSub.productName} - {selectedSub.duration}&nbsp;&nbsp;
              <span className="a-clone" onClick={() => setChangeRequested(true)}>
                Change
              </span>
            </b>
          </p>
        )}

        {selectedSub && !changeRequested && (
          <>
            <div className="custom-date-input">
              <label className="text-input-label">Skip my next Shipment :</label>
              <button className="updateSubModal-send-bttn orange" onClick={() => setShowSkipModal(true)}>
                Skip Shipment
              </button>
            </div>
            <p>or</p>
            <div className="custom-date-input" style={{ marginTop: '1rem', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
              <label className="text-input-label">Change Shipment Date: {getDateFromEpoch(selectedSub?.current_period_end, 'input')}</label>
              <div className="custom-date-input" style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <DatePicker
                  selected={updateShipmentDate || new Date(getDateFromEpoch(selectedSub?.current_period_end, 'input'))}
                  onChange={date => setUpdateShipmentDate(date)}
                  className="styled-calendar"
                  dateFormat="yyyy-MM-dd"
                  minDate={new Date()}
                />
                <button className="updateSubModal-send-bttn" onClick={() => setShowUpdateModal(true)} disabled={selectedSub?.metadata?.trial_used === 'true'}>
                  Update Shipment Date
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Modal for Skip Confirmation */}
      {showSkipModal && (
        <div className="modala-overlay">
          <div className="modala">
            <h3>Skip Shipment</h3>
            <p>You can skip a shipment only once. Are you sure you want to skip your next shipment?</p>
            <div className="modala-buttons">
              <button className="modala-button cancel" onClick={() => setShowSkipModal(false)}>
                Cancel
              </button>
              <button className="modala-button confirm" onClick={confirmSkipShipment}>
                Yes, Skip
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Update Confirmation */}
      {showUpdateModal && (
        <div className="modala-overlay">
          <div className="modala">
            <h3>Change Shipment Date</h3>
            <p>You can update your shipment date only once. Are you sure you want to change the date?</p>
            <div className="modala-buttons">
              <button className="modala-button cancel" onClick={() => setShowUpdateModal(false)}>
                Cancel
              </button>
              <button className="modala-button confirm" onClick={changeBillingDate}>
                Yes, Update
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="stripe-portal-wrapper">
        <div className="stripe-portal-card">
          <div className="stripe-portal-right">
            <h3 className="portal-title">Past/Cancelled Subscriptions</h3>
            <p className="portal-desc">View past and cancelled subscriptions here.</p>
            {subscriptionList
              ?.filter(sub => sub.status !== 'active' && sub.status !== 'trialing')
              .map((item, index) => (
                <div key={index} style={{ opacity: 0.5 }}>
                  <p>
                    {item.productName} - {item.duration} (${item.price.unit_amount / 100})
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageSubscription
