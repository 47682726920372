import Auth from "@aws-amplify/auth";
import axios from "axios";
import moment from "moment";
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import {getBackendBaseUrl,getFrontendBaseUrl} from "./config/config";

const secretValue = process.env.REACT_APP_TBD_API_KEY;

export class InternalApiService {

    async timeLimitCheck() {
        const a = moment(localStorage.getItem("countdownTime"));
        // if more than 3 hour has elapsed, signout and clear local storage
        if (a && moment().diff(a) > (3 * 60 * 60 * 1000)) {
            await Auth.signOut()
                .then(() => {
                    localStorage.clear();
                    if (window.location.pathname !== '/login') {
                        window.location.replace("/login");
                    } else {
                        window.location.replace("/");
                    }
                })
                .catch(err => {
                    console.error(err);
                })
            return null;
        } else {
            try{
                const sess = await Auth.currentSession();
                localStorage.setItem("loggedInToken", sess.getIdToken().getJwtToken());
                localStorage.setItem("countdownTime", moment());
                return sess;
            } catch(err){
                console.error(err);
                return null;
            }
        }
    }

    async getSubcriptionSession(email) {
        return axios({
            method: 'POST',
            url: `${getFrontendBaseUrl()}/api/stripe/subscription-managment-session`,
            data: { email: email }
        });
    }

    async getSubscriptionsList(email) {
        return axios({
            method: 'POST',
            url: `${getFrontendBaseUrl()}/api/stripe/fetch-stripe-subscriptions`,
            data: { email: email }
        });
    }

    async changeBillingDate(subscriptionId, newBillingAnchorDate, priceId) {
        return axios({
            method: 'POST',
            url: `${getFrontendBaseUrl()}/api/stripe/change-billing-date`,
            data: { subscriptionId: subscriptionId, newBillingAnchorDate: newBillingAnchorDate, priceId: priceId }
        });
    }

    async skipShipment(subscriptionId, newSkipDate) {
        return axios({
            method: 'POST',
            url: `${getFrontendBaseUrl()}/api/stripe/skip-shipment`,
            data: { subscriptionId: subscriptionId, newSkipDate: newSkipDate }
        });
    }

    async deleteSharedUser(recipientUUID) {
        await this.timeLimitCheck();
        return this.__sendRequest(`/secure/usersharing/share/${recipientUUID}`, "DELETE");
    }

    async getDuplicateEmailCheck(email) {
        return await this.__sendRequestNoToken(`/secure/user/email?email=${email}`, "GET");
    }

    async getKitExists() {
        await this.timeLimitCheck();
        return this.__sendRequest("/secure/kit/exists", "GET");
    }

    async getMessageCount() {
        await this.timeLimitCheck();
        return this.__sendRequest("/secure/message/new/count", "GET");
    }

    async getMessage(messageUUID) {
        await this.timeLimitCheck();
        return this.__sendRequest(`/secure/message/${messageUUID}`, "GET");
    }

    async getMessages() {
        await this.timeLimitCheck();
        return this.__sendRequest("/secure/message", "GET");
    }

    async getPatientAfterSessRef() {
        await this.timeLimitCheck();
        return this.__sendRequest("/secure/patient", "GET");
    }

    async getSentMessages() {
        await this.timeLimitCheck();
        return this.__sendRequest("/secure/message/sent", "GET");
    }

    async getSharedUsers(kitId) {
        await this.timeLimitCheck();
        return this.__sendRequest(`/secure/usersharing/${kitId}/share`, "GET");
    }

    async getUserHistory() {
        await this.timeLimitCheck();
        return this.__sendRequest("/secure/userhistory", "GET");
    }

    async getUserHistoryDownload(kitId, labResultName, responseType) {
        await this.timeLimitCheck();
        return this.__sendRequest(`/secure/userhistory/v2/download/${kitId}/${labResultName}`, "GET", responseType);
    }

    async validatePwdFor(kitId, fileName, pwd) {
        await this.timeLimitCheck();
        const reqBody = {kitId, fileName, pwd }
        return this.__postOrPutRequest(`/secure/userhistory/verifyFilePwd`, "POST", reqBody);
    }

    async getUserHistoryView(kitId, labResultName, responseType) {
        await this.timeLimitCheck();
        return this.__sendRequest(`/secure/userhistory/v2/viewTestResult/${kitId}/${labResultName}`, "GET", responseType);
    }

    async sendRequestToDownloadResultFile(kitId) {
        await this.timeLimitCheck();
        return this.__sendRequest(`/secure/userhistory/v2/reqResultDownload/${kitId}`, "GET");
    }

    async getUserPic() {
        await this.timeLimitCheck();
        return this.__sendRequest("/secure/user/pic", "GET");
    }

    async getUserSubscriptions() {
        await this.timeLimitCheck();
        return this.__sendRequest("/secure/patient/subscription", "GET");
    }

    async getUserSharing(kitId) {
        await this.timeLimitCheck();
        return this.__sendRequest(`/secure/usersharing/${kitId}/summary`, "GET");
    }

    async postMessage(data) {
        await this.timeLimitCheck();
        return this.__postOrPutRequest("/secure/message", "POST", data);
    }

    async postRegisterKit(data) {
        await this.timeLimitCheck();
        return this.__postOrPutRequest("/secure/kit", "POST", data);
    }

    async postHelp(data) {
        await this.timeLimitCheck();
        return this.__postOrPutRequest("/secure/question", "POST", data, "5tDnfOf9fjNibAljxchkFhvTo+9xnc9LdDKUJdfsBU8=");
    }

    async postReplyMessage(data) {
        await this.timeLimitCheck();
        return this.__postOrPutRequest("/secure/message/reply", "POST", data);
    }

    async postUserPic(data) {
        await this.timeLimitCheck();
        return this.__postOrPutRequest(`/secure/user/pic`, "POST", data);
    }

    async postUserSharingRecipients(kitId, data) {
        await this.timeLimitCheck();
        return this.__postOrPutRequest(`/secure/usersharing/${kitId}/share`, "POST", data);
    }

    async postWelcomeEmail(data) {
        return this.__postRequestNoToken(`/secure/message/welcome`, "POST", data);
    }

    async postUserHealthData(data) {
        return this.__postRequestNoToken(`/secure/patient`, "POST", data);
    }

    async updateUserHealthData(data) {
        return this.__postOrPutRequest(`/secure/patient/updatePatientDetails`, "POST", data);
    }

    async acknowledgeMessage(messageUUID) {
        await this.timeLimitCheck();
        return this.__postOrPutRequest(`/secure/message/acknowledge/${messageUUID}`, "POST", messageUUID);
    }

    async createSubSkipShippingRequest(data) {
      return this.__postOrPutRequest(
        `/secure/patient/subscription/skip`,
        "POST",
        data
      );
    }
    async createSubModifyRequest(data) {
      return this.__postOrPutRequest(
        `/secure/patient/subscription/modify`,
        "POST",
        data
      );
    }
    async createSubCancelRequest(data) {
      return this.__postOrPutRequest(
        `/secure/patient/subscription/cancel`,
        "POST",
        data
      );
    }



    async hasHealthProfile() {
        await this.timeLimitCheck();
        return this.__sendRequest(`/secure/patient/hasHealthProfile`, "GET");
    }

    async uploadFile(data, fileUseCaseType) {
        const formData = new FormData();
        formData.append('file', data);
        return await this.__uploadMultiPartFileRequest(`/secure/file/upload/${fileUseCaseType}`, 'POST', formData, 'multipart/form-data');
    }

    async downloadFile(fileUseCaseType, fileName) {
        return await this.__sendRequest(`/secure/file/download/${fileUseCaseType}/${fileName}`, 'GET', 'blob');
    }

    async deleteFile(fileUseCaseType, fileName) {
        return await this.__sendRequest(`/secure/file/delete/${fileUseCaseType}/${fileName}`, 'DELETE');
    }

    async hasHealthProfileSync() {
        let status = null;
        await this.timeLimitCheck();
        await this.__sendRequest(`/secure/patient/hasHealthProfile`, "GET")
            .then(resp => status = resp?.data)
            .catch(err => {
                status = "unknown";
            });
        return status;
    }

    async __sendRequest(url, method, responseType = null) {
        const hmac = Base64.stringify(hmacSHA256(url, secretValue));

        if (responseType === 'blob') {
            return axios({
                method: method,
                url: getBackendBaseUrl() + url,
                responseType: responseType,
                headers: {
                    "authorization": `Bearer ${localStorage.getItem("loggedInToken")}`,
                    "addresseeType": "P",
                    "content-type": "application/json",
                    "x-api-key": hmac
                }
            });
        } else {
            return axios({
                method: method,
                url: getBackendBaseUrl() + url,
                headers: {
                    "authorization": `Bearer ${localStorage.getItem("loggedInToken")}`,
                    "addresseeType": "P",
                    "content-type": "application/json",
                    "x-api-key": hmac
                }
            });
        }
    }

    async __uploadMultiPartFileRequest(url, method, data, contentType) {
        const hmac = Base64.stringify(hmacSHA256(url, secretValue));
        return axios({
            method: method,
            url: getBackendBaseUrl() + url,
            headers: {
                "authorization": `Bearer ${localStorage.getItem("loggedInToken")}`,
                "addresseeType": "P",
                "content-type": contentType,
                "x-api-key": hmac
            },
            data: data,
        })
      }

    __getHmacFor(data){
        return Base64.stringify(hmacSHA256(data, secretValue));
    }

    async __sendRequestNoToken(url, method) {
        const hmac = Base64.stringify(hmacSHA256(url, secretValue));

        return axios({
            method: method,
            url: getBackendBaseUrl() + url,
            headers: {
                "content-type": "application/json",
                "x-api-key": hmac
            }
        });
    }

    __postOrPutRequest(url, method, data, customApiKey) {
        let convertedData = "";
        if (data !== "") {
            convertedData = JSON.stringify(data);
        }
        const hmac = Base64.stringify(hmacSHA256(convertedData, secretValue)); // need to convert the data to JSON
        return axios({
            method: method,
            url: getBackendBaseUrl() + url,
            headers: {
                "authorization": `Bearer ${localStorage.getItem("loggedInToken")}`,
                "addresseeType": "P",
                "content-type": "application/json",
                "x-api-key": customApiKey || hmac
            },
            data: data
        })
    }

    __postRequestNoToken(url, method, data) {
        const hmac = Base64.stringify(hmacSHA256(JSON.stringify(data), secretValue)); // need to convert the data to JSON

        return axios({
            method: method,
            url: getBackendBaseUrl() + url,
            headers: {
                "x-api-key": hmac
            },
            data: data
        })
    }
}

export default new InternalApiService();
