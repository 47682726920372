import States from './zipcode/zipcode.js'
import _, { } from 'lodash'

export const getStates = () => {
    const stateCodes = []
    _.forEach(States, (state) => {
        if(state.active){
            stateCodes.push(state.code)
        }
    })
    return stateCodes;
}

export const getZipCodes = (code) => {
    const state = _.find(States, {code})
    const zipcodes = []
    _.forEach(state.zipcode, (code) => {
        zipcodes.push(state.code)
    })
    return zipcodes
}
export const eligibleState = (code) => {
    const state = _.find(States, {code})
    const valid = state ? state.active : false
    return valid
}

export const validZipCode = (statecode, zipcode) => {
    const statez = _.find(States, {code: statecode});
    const zipcodes = _.get(statez, 'zipcode', []);
    if(!zipcodes || zipcodes?.length === 0) {
        return true;
    }
    const idx = _.findIndex(zipcodes, (item) => {
        return (item.zip).toString() === (zipcode).toString();
    })
    return idx > -1;
}

export const validPhoneNumber = (phoneNumber) => {
    if(!phoneNumber) return phoneNumber;
    let testPhoneNumber = '';

    for (let i = 0; i < phoneNumber.length; i++) {
        if (!isNaN(parseInt(phoneNumber.charAt(i)))) {
            testPhoneNumber += parseInt(phoneNumber.charAt(i));
        }
    }

    if (testPhoneNumber.charAt(0) === '1') {
        return testPhoneNumber.length === 11;
    } else {
        return testPhoneNumber.length === 10;
    }
}

export const manipulatePhoneNumber = (address) => {
    const phoneNumber = _.pick(address, 'phoneNumber');
    if (!phoneNumber.phoneNumber.startsWith('+1')) {
        address['phoneNumber'] = "+1" + phoneNumber.phoneNumber;
    }
}

export const getBackendBaseUrl = ()  => {
    return process.env.REACT_APP_SERVER_URL;
}

export const getFrontendBaseUrl = () => {
    return process.env.REACT_APP_MAIN_SITE_BASE_URL;
}
